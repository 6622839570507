import { __identifier } from "~/stores/identifier.store";

export default defineNuxtRouteMiddleware(async to => {
	const identifierId = to.params.id;
	if (!identifierId) {
		log("❌ No identifier id found");
		return navigateTo("/");
	}

	log("✅ Has identifier id: " + identifierId);
	const id = __identifier().getIdentifierById(identifierId.toString());

	if (!id) {
		log("⌛ No identifier found, fetching");
		const newId = await __identifier().fetchIdentifierById(identifierId.toString());
		if (!newId) {
			log("❌ No identifier found");
			return navigateTo("/");
		}
	}

	log("✅ Identifier found");
});
